import React, { useLayoutEffect, useEffect, useState } from "react"
import { Link } from "gatsby"
import "./layout.css"
import styles from "./layout.module.css"

const NavbarLink = ({ name, href }) => <div className={styles.navbarLink}><Link activeClassName={styles.navbarActiveLink} to={href}>{name}</Link></div>

const Layout = ({ title, description, image, pathname, children }) => {
    const [showMe, setShowMe] = useState(false);
    const [styleVars, setStyleVars] = useState({
        outerBorderProportion: 0,
        fontSize: 12
    });
    function handleResize() {
        setStyleVars({
            outerBorderProportion: Math.min(Math.max((window.innerWidth-1000)/40,0),1),
            fontSize: 13+4*Math.min(Math.max((window.innerWidth-420)/640,0),1)
        });
    }
    useLayoutEffect(handleResize, []);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }); 
    // Don't show the website before js is loaded
    useEffect(() => {
        setShowMe(true);
    }, []);
    if (!showMe) {
        return null;
    }

    return (
        <>
            <div className={styles.outer} style={{'padding': 20*styleVars.outerBorderProportion+'px', 'fontSize': styleVars.fontSize+'px'}}>
                <div className={styles.inner} style={{'borderRadius': 12*styleVars.outerBorderProportion+'px'}}>
                    <div className={styles.header}>
                        <h1>Josh Kirklin</h1>
                        <div className={styles.navbar}>
                            <NavbarLink name="Home" href="/" />
                            <NavbarLink name="Research" href="/research/" />
                            <NavbarLink name="Notes" href="/notes/" />
                            <NavbarLink name="Other" href="/other/" />
                        </div>
                    </div>
                    <hr />
                    {children}
                    <hr />
                    <div className={styles.footer}>
                        Last updated <b>October 2024</b>.
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
